import React from 'react';
import Section from '../components/Section';
import './Home.css';
import CPlusPlusIcon from '../svgs/c-plusplus.svg';
import JavascriptIcon from '../svgs/javascript.svg';
import NodejsIcon from '../svgs/nodejs.svg';
import PythonIcon from '../svgs/python.svg';
import ReactIcon from '../svgs/react.svg';
import SwiftIcon from '../svgs/swift.svg';
import CssIcon from '../svgs/css-3.svg';
import HtmlIcon from '../svgs/html-5.svg';
import ExpressIcon from '../svgs/express.svg';
import MysqlIcon from '../svgs/mysql.svg';
import MongodbIcon from '../svgs/mongodb.svg';
import BashIcon from '../svgs/bash-icon.svg';
import AwsIcon from '../svgs/aws.svg';
import JenkinsIcon from '../svgs/jenkins.svg';
import DockerIcon from '../svgs/docker-icon.svg';
import GithubIcon from '../svgs/github.svg';
import EmailIcon from '../svgs/envelope-solid.svg';
import LinkedInIcon from '../svgs/linkedin.svg';
import NextJSIcon from '../svgs/nextjs.svg';
import DjangoIcon from '../svgs/django.svg';

const Home = () => {
  return (
    <div className="home-container">
      <h1>Hi, I'm Alexander Sangurima</h1>      
      <div className="contact-icons">
        <a href="https://github.com/alehxs" target="_blank" rel="noopener noreferrer">
          <img src={GithubIcon} alt="GitHub" />
        </a>
        <a href="mailto:asangurima@pm.me">
          <img src={EmailIcon} alt="Email" />
        </a>
        <a href="https://linkedin.com/in/asangurima" target="_blank" rel="noopener noreferrer">
          <img src={LinkedInIcon} alt="LinkedIn" />
        </a>
      </div>
      <Section
        title="whoami"
        body="I'm passionate about software engineering, iOS development, and cybersecurity.<br />I love exploring new technologies and solving complex problems.<br /> Outside of coding, I enjoy working out, boxing, and watching Formula 1."
      />
      <Section
        title="skills"
        body="Languages: "
      />
      <div className="prog-languages">
        <img src={PythonIcon} alt="Python" />
        <img src={CPlusPlusIcon} alt="C++" />
        <img src={HtmlIcon} alt="HTML5" />
        <img src={CssIcon} alt="CSS3" />
        <img src={JavascriptIcon} alt="JavaScript" />
        <img src={SwiftIcon} alt="Swift" />
        <img src={BashIcon} alt="Bash" />
      </div>
      
      <Section
        title=""
        body="Libraries and Frameworks: "/>
      <div className="libraries-and-frameworks">
      <div className="icon-wrapper">
          <img src={NodejsIcon} alt="Node.js" />
        </div>
        <img src={ReactIcon} alt="React.js" />
        <div className="icon-wrapper">
          <img src={NextJSIcon} alt="Next.js" />
        </div>
        <div className="icon-wrapper">
          <img src={ExpressIcon} alt="Express.js" />
        </div>
        <div className="icon-wrapper">
          <img src={DjangoIcon} alt="Django" />
        </div>
      </div>
      
      <Section
        title=""
        body="Other Technologies: " />
      <div className="other-technologies">
        <img src={MysqlIcon} alt="mySQL" />
        <div className="icon-wrapper">
          <img src={MongodbIcon} alt="mongoDB" />
        </div>
        <div className="icon-wrapper">
          <img src={AwsIcon} alt="AWS" />
        </div>

        <img src={JenkinsIcon} alt="Jenkins" />
        <img src={DockerIcon} alt="Docker" />
      </div>
      <Section
        title="projects"
        body=""/>
    </div>
  );
};

export default Home;